import { createSlice } from '@reduxjs/toolkit';
import { Meeting } from '../client';


export const meetingsSlice = createSlice({
    name: 'meetings',
    initialState: {
      value: [] as Meeting[],
    },
    reducers: {
      updateMeetings: (state, action) => {
        state.value = action.payload
      },
      updateSingle: (state, action) => {
        state.value = state.value.map(m => (m._id === action.payload._id ? action.payload : m))
      },
      addSingle: (state, action) => {
        state.value = [...state.value, action.payload]
      }
    },
})
  
export const { updateMeetings, updateSingle, addSingle } = meetingsSlice.actions
  
export const selectMeetings = (state) => state.meetings.value

export default meetingsSlice.reducer


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.MediaPlayer_video__mhOQg {
    max-width: 100%;
}


@media (min-width: 60rem) {
    .MediaPlayer_video__mhOQg {
      max-width: 60rem;
    }
  }

`, "",{"version":3,"sources":["webpack://./src/components/MediaPlayer/MediaPlayer.module.css"],"names":[],"mappings":";AACA;IACI,eAAe;AACnB;;;AAGA;IACI;MACE,gBAAgB;IAClB;EACF","sourcesContent":["\n.video {\n    max-width: 100%;\n}\n\n\n@media (min-width: 60rem) {\n    .video {\n      max-width: 60rem;\n    }\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video": `MediaPlayer_video__mhOQg`
};
export default ___CSS_LOADER_EXPORT___;

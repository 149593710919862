import React, { useEffect, useRef, useState } from 'react';
import MainPage from './pages/main';
import LoginPage from './pages/login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { User } from './client';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeetings, addSingle } from './redux/meetingSlice';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const baseURL = process.env.REACT_APP_FRONTEND_ROOT;

const App = () => {
  const dispatch = useDispatch();
  const meetings = useSelector(selectMeetings);

  const [token, setToken] = useState(localStorage.getItem('token'));
  const [user, setUser] = useState<User>();
  const wsConnection = useRef<null | WebSocket>(null);

  const getUser = async () => {
    try {
      const result = await axios.get(backendURL + '/user');
      
      if (result.status !== 200) {
        console.log('Error getting user data');
        setToken(null);
      }

      setUser(result.data)
    } catch (error) {
      console.log('Error getting user data');
      setToken(null);
    }
    
  }

  const getNewMeeting = async (meetingId: string) => {
    for (let meeting of meetings) {
      // Short circuit if meeting is already found
      if (meeting._id === meetingId) return;
    }

    const result = await axios.get(backendURL + `/meetings/${meetingId}`)

    if (result.status === 200) dispatch(addSingle(result.data));
  }

  useEffect(() => {
    if (token) {
      // Update parameters
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      localStorage.setItem('token', token);

      // Get user from backend
      getUser();
    }
  }, [token])

  useEffect(() => {
    // Once logged in, open the websocket for updates
    if (!user) return;

    const ws = new WebSocket(backendURL + '/ws/' + token)
    // Listen for messages
    ws.addEventListener("message", (event) => {
      const jsonData = JSON.parse(event.data);

      if (jsonData.status === "Completed") getNewMeeting(jsonData.meeting_id);

    })

    wsConnection.current = ws;

    return () => {
      ws.close()
    }
  }, [user])

  console.log(backendURL, baseURL)
  
  return (
    <BrowserRouter basename={baseURL}>
      <Routes>
        <Route path='/login' element={<LoginPage setToken={setToken}/>} />
        <Route path='/*' element={<MainPage user={user} isAuthenticated={!!token} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import { configureStore } from '@reduxjs/toolkit';
import meetingReducer from '../redux/meetingSlice'
import activeMeetingReducer from '../redux/activeMeetingSlice'
import filtersReducer from '../redux/filterSlice'

export default configureStore({
  reducer: {
    meetings: meetingReducer,
    activeMeeting: activeMeetingReducer,
    filters: filtersReducer
  },
});


import { createSlice } from '@reduxjs/toolkit';
import { Speaker } from '../client';

type Filters = {
    favourites: boolean;
    unreads: boolean;
    startDate: string | undefined;
    endDate: string | undefined;
    speakers: Speaker[];
};

export const filterSlice = createSlice({
    name: 'filters',
    initialState: {
        value: {
            favourites: false,
            unreads: false,
            startDate: undefined as (undefined | string),
            endDate: undefined as (undefined | Date),
            speakers: [] as Speaker[]
        } as Filters
    },
    reducers: {
      toggleFavourites: (state) => {
        state.value.favourites = !state.value.favourites;
      },
      toggleUnreads: (state) => {
        state.value.unreads = !state.value.unreads;
      },
      setStart: (state, action) => {
        state.value.startDate = action.payload;
      },
      setEnd: (state, action) => {
        state.value.endDate = action.payload;
      },
      addSpeaker: (state, action) => {
        state.value.speakers = [...state.value.speakers, action.payload];
      },
      removeSpeaker: (state, action) => {
        state.value.speakers = state.value.speakers.filter(spk => spk._id !== action.payload._id)
      },
      clearFilters: (state) => {
        state.value = {
            favourites: false,
            unreads: false,
            startDate: undefined as (undefined | string),
            endDate: undefined as (string | undefined),
            speakers: [] as Speaker[]
        } as Filters
      }
    },
})

export const { 
  toggleFavourites, 
  toggleUnreads, 
  setStart, 
  setEnd, 
  clearFilters,
  addSpeaker,
  removeSpeaker,
} = filterSlice.actions
export type { Filters };
export const selectFilters = (state) => state.filters.value

export default filterSlice.reducer


import React, { useState } from "react";
import styles from './login.module.css'
import axios from 'axios';
import { useNavigate } from "react-router-dom";


const LoginPage = ({setToken}) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();


    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault()
        try {
            const options = {
                method: 'POST',
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
                data: `username=${username}&password=${password}`,
                url: backendURL + "/token",
            };

            const result = await axios(options)

            if (result.status !== 200) {
                setError(result.statusText);
                console.log(result.statusText)
                return;
            }

            const meetingsData = result.data;

            setToken(meetingsData['access_token']);
            navigate('/');
        } catch (err) {
            setError('Error logging in with provided credentials');
        }
    };

    const changeUsername: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setUsername(e.target.value);
        setError('');
    }

    const changePassword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setPassword(e.target.value);
        setError('');
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <form onSubmit={handleSubmit} className={styles['loginForm']}>
                <label htmlFor="username">Username:</label>
                <input
                    className={styles['loginInput']}
                    type="text"
                    id="username"
                    value={username}
                    onChange={changeUsername}
                    required
                    />
                <label htmlFor="password" style={{ marginTop: '10px' }}>Password:</label>
                <input
                    className={styles['loginInput']}
                    type="password"
                    id="password"
                    value={password}
                    onChange={changePassword}
                    required
                    />
                <button type="submit" style={{ marginTop: '20px' }}>Login</button>
                <label style={{color: 'red'}}> {error} </label>
            </form>
        </div>
    );
}

export default LoginPage;

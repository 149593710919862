import React from 'react';
import { ReactComponent as Logo } from '../assets/white-logo.svg';
import { ReactComponent as MCG } from '../assets/white-horizontal.svg';
import './Header.css';
import { Link } from 'react-router-dom';

const Header = ({user}) => {
  return (
    <header className="header-container">
      <h1 className="text-3xl inter-font">Meeting Tools</h1>
      <div className="flex items-center">
        { user 
          ? <h3 className='welcome-text'>Welcome, {user['name']}</h3>
          : <Link to={'login'} className='login-button'>Login</Link>
        }
        <Logo className="white-logo animate-hover" aria-label="logo" />
        <MCG className="white-MCG" aria-label="logo"/>
      </div>
    </header>
  );
};

export default Header;

import ReactPlayer from 'react-player';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import LoadingGif from '../assets/loading.gif';
import styles from './MediaPlayer.module.css';

type Props = {
    meeting_id: string;
    recording_type: string;
    seekTo?: number;
};

const backendURL = process.env.REACT_APP_BACKEND_URL;

const MediaPlayer = ({
    meeting_id,
    recording_type,
    seekTo
}: Props) => {
    const playerRef = useRef<ReactPlayer | null>(null);
    const [audioSource, setSource] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [playing, setPlaying] = useState(false);

    const downloadMedia = async() => {
        if (recording_type.startsWith('audio')) {
            axios({
                url: backendURL + `/meetings/${meeting_id}/media`, 
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                setLoading(false);
    
                if (response.status !== 200) {
                    setError(response.statusText);
                    return;
                }
    
                const href = URL.createObjectURL(response.data);
                
                setSource(href);
            }).catch((error) => {
                setLoading(false);
                setError(error);
            })
        } else {
            setLoading(false);
            setError('');
            setSource('');
        }
    }

    useEffect(() => {
        downloadMedia();
    }, [recording_type])

    useEffect(() => {
        playerRef.current?.seekTo(((seekTo && seekTo > 1) ? seekTo : 1) - 1, "seconds")
        setPlaying(true);
    }, [seekTo])

    const isAudio = recording_type.startsWith('audio');
    const isVideo = recording_type.startsWith('video');
    const videoSource = backendURL + `/stream/${meeting_id}/stream.m3u8`;

    return (
        <> 
            {error !== '' && <h3>{error}</h3>}
            {loading && <img src={LoadingGif} style={{width: '40px'}}/>}
            {!loading && isAudio && audioSource && <audio controls src={audioSource}/>}
            {isVideo &&
                <div className={styles['video']}>
                    <ReactPlayer
                        ref={playerRef}
                        url={videoSource}
                        controls
                        width="100%"
                        height="100%"
                        playing={playing}
                        onPause={() => setPlaying(false)}
                        config={{
                        file: {
                            hlsOptions: {
                                xhrSetup: (xhr) => {
                                    xhr.setRequestHeader(
                                        'Authorization', 
                                        axios.defaults.headers.common["Authorization"]
                                    );
                                },
                            },
                        },
                        }}
                    /> 
                </div>
            }
        </>
    );
}

export default MediaPlayer;

import React from "react";
import styles from './FiltersView.module.css';
import { useDispatch, useSelector } from "react-redux";
import { clearFilters, removeSpeaker, selectFilters, setStart, toggleFavourites, toggleUnreads } from "../../redux/filterSlice";
import { ReactComponent as Cancel } from "../assets/cancel.svg";
import { ReactComponent as Clear } from "../assets/clear.svg";


const FiltersView = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);

    const shouldShow: boolean = (filters.favourites) || (filters.unreads) || (filters.startDate) || (filters.speakers.length > 0);
    const dateRange: boolean = (filters.startDate) && (filters.endDate);

    return (shouldShow &&
        <div className="flex flex-row mb-3 gap-2 align-middle">
            <button className={styles['clear-button']} title="Clear filters" onClick={() => dispatch(clearFilters())}>
                <Clear />
            </button>
            {filters.favourites && 
                <Chip text="Favourites" onRemove={() => dispatch(toggleFavourites())}/>
            }
            {filters.unreads && 
                <Chip text="Unread" onRemove={() => dispatch(toggleUnreads())}/>
            }
            {!dateRange && filters.startDate && 
                <Chip text={filters.startDate} onRemove={() => dispatch(setStart(undefined))}/>
            }
            {dateRange && 
                <Chip text={`${filters.startDate} - ${filters.endDate}`} />
            }
            {filters.speakers.length > 0 &&
                filters.speakers.map(speaker => 
                    <Chip 
                        text={`${speaker.first_name} ${speaker.last_name}`} 
                        onRemove={() => dispatch(removeSpeaker(speaker))} />
                )
            }
        </div>
    );
}

type ChipProps = {
    text: string;
    onRemove?: (any) => void;
}

const Chip = ({text, onRemove}: ChipProps) => {
    return (
        <div className={styles['chip']}>
            {text}
            <div className={styles['cancel']} onClick={onRemove}> <Cancel /> </div>
        </div>
    )
}

export default FiltersView;

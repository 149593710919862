import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import MeetingCard from '../MeetingCard/MeetingCard';
import 'react-calendar/dist/Calendar.css';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { ReactComponent as CalendarIcon } from '../assets/calendar-icon.svg';
import { ReactComponent as PersonAdd } from '../assets/identify.svg';
import { selectMeetings } from '../../redux/meetingSlice';
import { selectFilters, Filters, setStart, setEnd, addSpeaker } from '../../redux/filterSlice';
import './MeetingList.css';
import { useDispatch, useSelector } from 'react-redux';
import { Meeting } from '../../client';
import FiltersView from '../FiltersView/FiltersView';
import Popup from 'reactjs-popup';
import SpeakerAssigner from '../SpeakerAssigner/SpeakerAssigner';

type Props = {
  onMeetingSelect: (meetingId: string | null | undefined) => void;
  selectedMeetingId: string | null;
  onToggleFavourite: (meetingId: string) => void;
  onToggleReadStatus: (meetingId: string) => void;
};

const MeetingList = ({
  onMeetingSelect,
  selectedMeetingId,
  onToggleFavourite,
  onToggleReadStatus
}: Props) => {
  const dispatch = useDispatch();
  const meetings: Meeting[] = useSelector(selectMeetings);
  const filters: Filters = useSelector(selectFilters)

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredMeetings, setFilteredMeetings] = useState<Array<any>>([]);
  const [meetingDates, setMeetingDates] = useState<Set<string>>(new Set());

  const meetingsFilter = (meeting: Meeting) => {
    if (filters.favourites && !meeting.is_favourite) return false;

    if (filters.unreads && meeting.is_read) return false;

    if (filters.startDate && filters.endDate) {
      // Range selected
      const dateString = meeting.date.slice(0, 10) // "YYYY-MM-DD".length = 10
      if (dateString < filters.startDate || dateString > filters.endDate) return false;
    } else if (filters.startDate) {
      // Day selected
      if (!meeting.date.startsWith(filters.startDate)) return false;
    }

    if (filters.speakers.length > 0) {
      const speakerIdSet = new Set<string>();
      meeting.speakers.forEach(speaker => speakerIdSet.add(speaker._id ?? ''))

      for (const speaker of filters.speakers) {
        if (!speakerIdSet.has(speaker._id ?? '')) return false;
      }
    }

    if (searchTerm && searchTerm !== '') {
      const searchLower = searchTerm.toLowerCase()
      if (meeting.name.toLowerCase().indexOf(searchLower) === -1) {
        if (meeting.summary?.toLowerCase().indexOf(searchLower) === -1) return false
      }
    }

    return true;
  }

  useEffect(() => {
    let filtered = meetings.filter(meetingsFilter)

    setFilteredMeetings(filtered);

    const dates = new Set<string>();

    meetings.forEach(meeting => {
      const meetingDate = new Date(meeting.date).toLocaleDateString('en-CA');
      dates.add(meetingDate);
    });

    setMeetingDates(dates);
  }, [meetings, searchTerm, filters]);

  // Sort meetings by date (newest to oldest)
  const sortedMeetings = filteredMeetings.slice().sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const handleDateChange = (selectedDate: Value) => {
    if (Array.isArray(selectedDate)) {
      dispatch(setStart(selectedDate[0]?.toLocaleDateString('en-CA')))
      dispatch(setEnd(selectedDate[1]?.toLocaleDateString('en-CA')))
    } else {
      const formattedSelectedDate = selectedDate ? selectedDate.toLocaleDateString('en-CA') : undefined;
      dispatch(setStart(formattedSelectedDate))
    }
  };

  const highlightSelectedDate = ({ date }: { date: Date }) => {
    if (!date) return '';

    const formattedDate = date.toLocaleDateString('en-CA');
    const isMeetingDate = meetingDates.has(formattedDate);

    return isMeetingDate ? 'meeting-date' : '';
  };

  const handleMeetingSelect = (meetingId: string | null | undefined) => {
    if (selectedMeetingId === meetingId) {
      onMeetingSelect(null);
    } else {
      onMeetingSelect(meetingId);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const local = filters.startDate ? new Date(filters.startDate) : new Date()
  const utc = new Date(
    local.getUTCFullYear(), local.getUTCMonth(),
    local.getUTCDate(), local.getUTCHours(),
    local.getUTCMinutes(), local.getUTCSeconds()
  );

  return (
    <div className="flex flex-col h-full p-4">
      <div className="flex mb-4 min-w-min">
        <div className="input-container flex-1">
          <input
            type="text"
            placeholder="Search meetings..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-1 p-2 border rounded-lg"
          />
          {searchTerm && (
            <button
              className="clear-button"
              onClick={handleClearSearch}
            >
              &times;
            </button>
          )}
        </div>

        {/* Calendar button */}
        <Popup 
          trigger={
            <button
              className="ml-2 p-2 border rounded-lg date-button"
              title="Filter by date"
            >
              <CalendarIcon className='w-auto h-auto' />
            </button>
          }
          position="bottom center">
            <Calendar
              onChange={handleDateChange}
              value={utc}
              tileClassName={highlightSelectedDate}
              defaultView="month"
            />
        </Popup>

        {/* Speaker popup */}
        <Popup 
          trigger={
            <button
              className="ml-2 p-2 border rounded-lg date-button"
              title="Filter by speaker"
            >
              <PersonAdd className='w-auto h-auto' />
            </button>
          }
          position="bottom center">
            <SpeakerAssigner 
              noCreate 
              overideReassign={speaker => dispatch(addSpeaker(speaker))} />
        </Popup>
      </div>
      
      <FiltersView />

      <div className="meeting-list-container flex-grow overflow-y-auto w-full">
        {meetings.length === 0 ? (
          <div className="flex items-center justify-center h-48 text-gray-500">
            <p>No meetings available</p>
          </div>
        ) : (
          filteredMeetings.length === 0 ? (
            <div className="flex items-center justify-center h-48 text-gray-500">
              <p>No meetings match your search</p>
            </div>
          ) : (
            sortedMeetings.map(meeting => (
              <MeetingCard
                key={meeting._id}
                meeting={meeting}
                isSelected={meeting._id === selectedMeetingId}
                onClick={() => handleMeetingSelect(meeting._id)}
                onToggleFavourite={() => onToggleFavourite(meeting._id)}
                onToggleReadStatus={() => onToggleReadStatus(meeting._id)}
              />
            ))
          )
        )}
      </div>
    </div>
  );
};

export default MeetingList;

import { createSlice } from '@reduxjs/toolkit';
import { Meeting } from '../client';

export const activeMeetingSlice = createSlice({
    name: 'activeMeeting',
    initialState: {
      value: undefined as undefined | Meeting,
    },
    reducers: {
      setActive: (state, action) => {
        state.value = action.payload
      },
    },
})

export const { setActive } = activeMeetingSlice.actions
  
export const selectActiveMeeting = (state) => state.activeMeeting.value

export default activeMeetingSlice.reducer
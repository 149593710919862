import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header/Header';
import MeetingList from '../components/MeetingList/MeetingList';
import MeetingDetails from '../components/MeetingDetails/MeetingDetails';
import Toolbar from '../components/Toolbar/Toolbar';
import { Meeting, User } from '../client';
import Uploader from '../components/Uploader/Uploader';
import { useDispatch, useSelector } from 'react-redux';
import { selectMeetings, updateMeetings, updateSingle } from '../redux/meetingSlice';
import { selectActiveMeeting, setActive } from '../redux/activeMeetingSlice';
import { useNavigate } from 'react-router-dom';


const backendURL = process.env.REACT_APP_BACKEND_URL;

type Props = {
  user: User | undefined;
  isAuthenticated: boolean;
}

const MainPage = ({
  user, 
  isAuthenticated
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const meetings: Meeting[] | undefined = useSelector(selectMeetings);
  const activeMeeting: Meeting | undefined = useSelector(selectActiveMeeting);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [uploading, setUploading] = useState(false);

  const fetchMeetings = async () => {
    try {
      const response = await axios.get(backendURL + "/meetings");
      const meetingsData: Meeting[] = response.data;

      dispatch(updateMeetings(meetingsData));
    } catch (error) {
      console.error("Error fetching meetings: ", error);
    }
  };

  useEffect(() => {
    // If not logged in, redirect to log in page
    if (!isAuthenticated) {
      console.log('User not identified');
      navigate('/login', { replace: true});
    } else {
      fetchMeetings();
    }
  }, []);

  const handleMeetingSelect = async (meetingId: string | null | undefined) => {
    if (!meetingId) {
      dispatch(setActive(undefined));
      return;
    }

    const selected = meetings?.find(meeting => meeting._id === meetingId);
    if (!selected) {
      console.error("Meeting not found:", meetingId);
      return;
    }

    if (!selected.is_read) {
      await handleUnreadToggle(meetingId);
    }

    dispatch(setActive(selected));
  };

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleToggleFavourite = async (meetingId: string) => {
    try {
      const meeting = meetings?.find(m => m._id === meetingId);
      if (!meeting) return;

      const updatedMeeting: Meeting = { ...meeting, is_favourite: !meeting.is_favourite };
      await axios.put(backendURL + `/meetings/${meetingId}`, updatedMeeting);

      dispatch(updateSingle(updatedMeeting));
      if (updatedMeeting._id === activeMeeting?._id) dispatch(setActive(updatedMeeting))
    } catch (error) {
      console.error("Error toggling favourite status:", error);
    }
  };

  const handleUnreadToggle = async (meetingId: string) => {
    try {
      const meeting = meetings?.find(m => m._id === meetingId);
      if (!meeting) return;
  
      const updatedMeeting = { ...meeting, is_read: !meeting.is_read };
      await axios.put(backendURL + `/meetings/${meetingId}`, updatedMeeting);
  
      dispatch(updateSingle(updatedMeeting));
      if (updatedMeeting._id === activeMeeting?._id) dispatch(setActive(updatedMeeting))
    } catch (error) {
      console.error("Error toggling read status:", error);
    }
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Header user={user} />
      <div className="flex flex-grow overflow-hidden">
        <div className={`flex ${isCollapsed ? 'w-16' : 'w-custom-30'} transition-width duration-300 bg-gray-100 border-r overflow-hidden`}>
          <Toolbar
            onToggleCollapse={handleToggleCollapse}
            isCollapsed={isCollapsed}
            setUploading={setUploading}
            isUploading={uploading}
          />
          {!isCollapsed && (
            <div className="flex flex-col flex-grow overflow-hidden">
              <MeetingList
                onMeetingSelect={handleMeetingSelect}
                selectedMeetingId={activeMeeting?._id ? activeMeeting?._id : null}
                onToggleFavourite={handleToggleFavourite}
                onToggleReadStatus={handleUnreadToggle}
              />
            </div>
          )}
        </div>
        <div className={`flex-grow transition-width duration-300 ${isCollapsed ? 'w-full' : 'w-3/4'} p-4 overflow-y-auto`}>
            {uploading
              ? <Uploader setUploading={setUploading}/>
              : <MeetingDetails />
            }
        </div>
      </div>
    </div>
  );
};

export default MainPage;

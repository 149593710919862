import React from 'react';
import { ReactComponent as PDFIcon } from '../assets/pdf-toolbar-icon.svg';
import { ReactComponent as StarIcon } from '../assets/star-toolbar-icon.svg';
import { ReactComponent as UnreadIcon } from '../assets/unread-toolbar-icon.svg';
import { ReactComponent as UplaodIcon } from '../assets/upload-icon.svg';
import { format, parseISO } from 'date-fns';
import './Toolbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { Filters, selectFilters, toggleFavourites, toggleUnreads } from '../../redux/filterSlice';
import { Meeting } from '../../client';
import { jsPDF } from 'jspdf';
import { selectActiveMeeting } from '../../redux/activeMeetingSlice';


type Props = {
  onToggleCollapse: () => void;
  isCollapsed: boolean;
  setUploading: (newVal: boolean) => void;
  isUploading: boolean;
};


const findSpeakerName = (speakerId: string, meeting: Meeting): string => {
  // Case if speaker is a diarization default
  if (speakerId.startsWith('SPEAKER_')) return speakerId;

  const speaker = meeting.speakers.find(speaker => speaker._id === speakerId);
  return speaker ? `${speaker.first_name} ${speaker.last_name}` : 'Speaker Not Found';
};


const exportToPDF = (meeting: Meeting) => {
  if (!meeting) return;

  const parsedDate = parseISO(meeting.date);
  const formattedDate = `${format(parsedDate, 'EEE, MMM d, yyyy, h:mm a')}`;

  const doc = new jsPDF();

  const margin = 10;
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  const lineHeight = 10;
  const headingFontSize = 16;
  const bodyFontSize = 12;
  doc.setFont("helvetica");

  // Title
  doc.setFontSize(headingFontSize);
  let yOffset = margin + 10;
  doc.text(`Meeting: ${meeting.name}`, margin, yOffset);
  yOffset += 10;

  // Date
  doc.setFontSize(bodyFontSize);
  doc.text(`Date: ${formattedDate}`, margin, yOffset);
  yOffset += 20;

  // Function to wrap text
  doc.setFontSize(bodyFontSize);
  const wrapText = (text: string, x: number, y: number, maxWidth: number): number => {
    const lines = doc.splitTextToSize(text, maxWidth);
    lines.forEach((line: string) => {
      if (y + lineHeight > pageHeight - margin) {
        doc.addPage();
        y = margin;
      }
      doc.text(line, x, y);
      y += lineHeight;
    });
    return y;
  };

  // Speakers section
  wrapText('Speakers:', margin, yOffset, pageWidth - 2 * margin);
  yOffset += lineHeight;
  meeting.speakers.forEach((speaker, index) => {
    const text = `${index + 1}. ${speaker.first_name} ${speaker.last_name} - ${speaker.email}`;
    yOffset = wrapText(text, margin, yOffset, pageWidth - 2 * margin);
  });

  // Transcripts section
  yOffset += lineHeight;
  wrapText('Transcripts:', margin, yOffset, pageWidth - 2 * margin);
  yOffset += lineHeight;
  meeting.transcripts.forEach((transcript, index) => {
    const speakerName = findSpeakerName(transcript.speaker_id ?? '', meeting);
    const transcriptText = `${index + 1}. Speaker: ${speakerName}\n   Time: ${transcript.time}\n   Text: ${transcript.text}`;
    yOffset = wrapText(transcriptText, margin, yOffset, pageWidth - 2 * margin);
  });

  // Save the PDF to Downloads
  doc.save(`${meeting.name}.pdf`);
};


const Toolbar = ({
  onToggleCollapse,
  isCollapsed,
  setUploading,
  isUploading
}: Props) => {
  const dispatch = useDispatch();
  const filters: Filters = useSelector(selectFilters);
  const meeting: Meeting = useSelector(selectActiveMeeting);

  return (
    <div 
      className={`flex flex-col items-center bg-gray-200 p-2 border-r h-full ${isCollapsed ? 'w-16' : 'w-16'}`} 
      onClick={() => {if (isUploading) setUploading(false)}}
      >

      <button
        onClick={onToggleCollapse}
        className="toolbar-button mb-4"
        title={isCollapsed ? 'Expand menu' : 'Collapse menu'}
      >
        {isCollapsed ? '>' : '<'}
      </button>
      <button
        onClick={() => dispatch(toggleFavourites())}
        className={`toolbar-button mb-4 ${filters.favourites ? 'toolbar-button-highlight' : ''}`}
        title={filters.favourites ? 'Clear favourites filter' : 'Filter to favourites'}
      >
        <StarIcon />
      </button>
      <button
        onClick={() => dispatch(toggleUnreads())}
        className={`toolbar-button mb-4 ${filters.unreads ? 'toolbar-button-highlight' : ''}`}
        title={filters.unreads ? 'Clear unread filter' : 'Filter to unread meetings'}
      >
        <UnreadIcon />
      </button>
      <button
        onClick={() => exportToPDF(meeting)}
        className="toolbar-button mb-4"
        title={"Save as PDF"}
      >
        <PDFIcon />
      </button>
      <button
        onClick={() => setUploading(true)}
        className="toolbar-button mb-4"
        title={"Upload file"}
      >
        <UplaodIcon />
      </button>
    </div>
  );
};

export default Toolbar;

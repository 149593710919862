// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meeting-card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  margin: 1px;
  width: 24px;
  height: 24px;
}

.meeting-card-summary {
  max-height: 3rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; 
  line-clamp: 2;
}

.meeting-card-favourite-button svg {
  width: 18px;
  height: 18px;
}

.meeting-card-read-button svg {
  width: 24px;
  height: 24px;
}

.meeting-card-unread {
  border: 2px solid #000000;
}
`, "",{"version":3,"sources":["webpack://./src/components/MeetingCard/MeetingCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,WAAW;EACX,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;EACpB,4BAA4B;EAC5B,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".meeting-card-button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: none;\n  border: none;\n  cursor: pointer;\n  margin: 1px;\n  width: 24px;\n  height: 24px;\n}\n\n.meeting-card-summary {\n  max-height: 3rem;\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2; \n  line-clamp: 2;\n}\n\n.meeting-card-favourite-button svg {\n  width: 18px;\n  height: 18px;\n}\n\n.meeting-card-read-button svg {\n  width: 24px;\n  height: 24px;\n}\n\n.meeting-card-unread {\n  border: 2px solid #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import styles from './Uploader.module.css'
import axios from 'axios';
import LoadingGif from '../assets/loading.gif';

type Props = {
    setUploading: (newVal: boolean) => void
}

const Uploader = ({
    setUploading
}: Props) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [file, setFile] = useState<File | null>(null);
    const [name, setName] = useState('');
    const [ext, setExt] = useState('');
    const [error, setError] = useState('');
    const [sending, setSending] = useState(false);

    const acceptedFileTypes = 'audio/*,video/*'

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();

        if (!file) {
            setError('Error: file not found');
            return;
        }

        let formData = new FormData();

        const newFile = new File([file], `${name}${ext}`, {type: file.type});

        formData.append('file', newFile)

        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(backendURL + '/upload', formData, config)
            .then(response => {
                setSending(false);
                if (response.status !== 200) {
                    setError(response.statusText);
                } else {
                    setUploading(false);
                    alert("File has been uploaded. Once it has finished processing, it will appear in your meetings list.")
                }
            })
            .catch(err => {
                setSending(false);
                setError('Error uploading file');
            })
        
        setSending(true);
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const newFile = e.target.files[0]
            if (newFile) {
                setFile(newFile);
                const cutoff = newFile.name.lastIndexOf('.')
                setExt(newFile.name.substring(cutoff))
                if (name.length === 0) setName(newFile.name.substring(0, cutoff))
                setError('');
            }
        }
    };

    return (
        <>
            <button className={styles['cancel-button']} onClick={() => setUploading(false)}>
                Cancel
            </button>
            <div className="flex items-center justify-center flex-col">
                <form onSubmit={handleSubmit} className={styles['loginForm']}>
                    <label htmlFor="username">Upload file:</label>
                    <input 
                        style={{paddingBottom: '1rem'}}
                        type="file"
                        id="file"
                        multiple={false}
                        onChange={handleFileChange}
                        accept={acceptedFileTypes}
                        required
                        />
                    <label htmlFor="username">Meeting title:</label>
                    <input
                        className={styles['loginInput']}
                        type="text"
                        id="username"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        />
                    {file && <button type="submit" style={{ marginTop: '20px' }}>Submit File</button>}
                    <label style={{color: 'red'}}> {error} </label>
                </form>
                {sending && 
                    <div className={styles['loading']}>
                        <h3> Submitting file... (this can take a minute) </h3>
                        <img src={LoadingGif} style={{width: '50px'}}/>
                    </div>
                }
            </div>
        </>
    );
}


export default Uploader;

import React, { useEffect, useState } from 'react';
import styles from './SpeakerAssigner.module.css';
import { Meeting, Speaker, ReassignmentSchema } from '../../client';
import SpeakerCreator from './SpeakerCreator';
import axios from 'axios';
import { selectActiveMeeting, setActive } from '../../redux/activeMeetingSlice'
import { useDispatch, useSelector } from 'react-redux';
import { updateSingle } from '../../redux/meetingSlice';

type Props = {
    transcriptIds?: Set<string>;
    onSubmit?: () => void;
    overideReassign?: (speaker: Speaker) => void;
    noCreate?: boolean;
};

const backendURL = process.env.REACT_APP_BACKEND_URL;

const SpeakerAssigner = ({
    transcriptIds,
    onSubmit,
    overideReassign,
    noCreate
}: Props) => {
    const meeting: Meeting | undefined = useSelector(selectActiveMeeting);
    const dispatch = useDispatch();

    const [info, setInfo] = useState('');
    const [speakers, setSpeakers] = useState<Speaker[] | undefined>(undefined);
    const [searchedSpeakers, setSearchedSpeakers] = useState<Speaker[] | undefined>(undefined);
    const [creating, setCreating] = useState(false);
    const [error, setError] = useState('');

    const updateMeeting = (newMeeting: Meeting) => {
        axios.put(backendURL + `/meetings/${newMeeting._id}`,
            newMeeting
        ).then((res) => {
            if (res.status === 200) {
                const newMeeting: Meeting = res.data
                dispatch(setActive(newMeeting));
                dispatch(updateSingle(newMeeting));
                if (onSubmit) onSubmit();
            }
        })
    }

    const sendReassignments = async (
        newId: string,
        oldIds: string[],
        transIds: string[],
        meetingId: string
    ) => {
        if (transIds.length === 0) return;

        await axios.post(backendURL + `/identify/${meetingId}`,{
            speaker_ids: oldIds,
            new_speaker_id: newId,
            transcript_ids: transIds
        } as ReassignmentSchema).then((res) => {
            if (res.status !== 200) {
                console.log(res.data)
                setError(res.statusText)
            }
        })
    }

    const assignSpeaker = (newSpeaker: Speaker) => {
        // No one to reassign
        if (!transcriptIds || transcriptIds?.size === 0) return;

        if (!meeting) return;
        let newMeeting = {...meeting};

        let updatedSpeakersIds = new Set<string>();
        let allSpeakersIds = new Set<string>();
        let transIds: string[] = []
        allSpeakersIds.add(newSpeaker._id ?? '')
        newMeeting.transcripts = meeting.transcripts.map(trans => {
            if (transcriptIds.has(trans.id ?? '')) {
                // Check for self assignment
                if (trans.speaker_id === newSpeaker._id) {
                    return trans;
                }

                const newTrans = {...trans}
                if (!newTrans.speaker_id?.startsWith('SPEAKER_')) updatedSpeakersIds.add(newTrans.speaker_id ?? '');
                newTrans.speaker_id = newSpeaker._id;
                newTrans.auto_identified = false;
                transIds.push(newTrans.id ?? '')
                return newTrans;
            }
            allSpeakersIds.add(trans.speaker_id ?? '')
            return trans;
        })

        // Add new speaker if they are new, remove old speaker if they are gone

        let newIsNotHere = true
        newMeeting.speakers = newMeeting.speakers.filter(speaker => {
            if (speaker._id === newSpeaker._id) newIsNotHere = false;
            return speaker._id ? allSpeakersIds.has(speaker._id) : allSpeakersIds.has(speaker.first_name);
        })

        if (newIsNotHere)  {
            newMeeting.speakers = [...newMeeting.speakers, newSpeaker];
        }

        sendReassignments(newSpeaker._id ?? '', Array.from(updatedSpeakersIds), transIds, meeting._id ?? '').then(() => {
            updateMeeting(newMeeting);
        })
    }

    const getSpeakers = async () => {
        axios.get(backendURL + '/speakers').then(results => {
            if (results.status !== 200) {
                setError(results.statusText);
            }
            setSpeakers(results.data);
            setError('');
        }).catch(err => {
            setError(err);
        });          
    }

    const onLookup: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        const newInfo = event.target.value;
        setInfo(newInfo);
    };
    
    const closeCreateAndRefreshSpeakers = async () => {
        getSpeakers().then(() => setCreating(false));
    }

    useEffect(() => {
        getSpeakers();
    }, [])

    useEffect(() => {
        const searchString = info.toLowerCase();
        let newSpeakers: Speaker[] | undefined = speakers?.filter(speaker => {
            if (speaker.first_name.toLowerCase().includes(searchString)) return true;
            if (speaker.last_name.toLowerCase().includes(searchString)) return true;
            if (speaker.email.toLowerCase().includes(searchString)) return true;
            return false;
        })

        setSearchedSpeakers(newSpeakers)
    }, [speakers, info])

    return (
        <div className={styles['modal']}>
            { !creating ?
                <>
                    <div style={{display: 'flex'}}>
                        <input
                            type='text'
                            value={info}
                            onChange={onLookup}
                            className={styles['search']}
                            placeholder="Search speakers..."
                            style={{marginRight: '10px'}}
                            />
                        {!noCreate && <button 
                            onClick={() => setCreating(true)}
                            className={styles['action-button']}>
                            Create New
                        </button>}
                    </div>
                    <div className={styles['speaker-list']}>
                        {(error === '') && searchedSpeakers && searchedSpeakers.length > 0 ? 
                            searchedSpeakers.map((speaker, index) => 
                                <button
                                    style={{display: 'block'}}
                                    key={index}
                                    onClick={() => overideReassign ? overideReassign(speaker) : assignSpeaker(speaker)}>
                                    {`${speaker.first_name} ${speaker.last_name} <${speaker.email}>`}
                                </button>
                            )
                        : <p className={styles['empty-speakers']}> {error ? error : "No speakers found"} </p> }
                    </div>
                </>
            : <SpeakerCreator afterCreate={() => closeCreateAndRefreshSpeakers()}/>}
        </div>
    );
}

export default SpeakerAssigner;

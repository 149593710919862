import React, { useState, forwardRef, useReducer } from 'react';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { ConfirmationSchema, Meeting, Speaker, Transcript } from '../../client';
import './MeetingDetails.css';
import MediaPlayer from '../MediaPlayer/MediaPlayer';
import Popup from 'reactjs-popup';
import SpeakerAssigner from '../SpeakerAssigner/SpeakerAssigner';
import { selectActiveMeeting, setActive } from '../../redux/activeMeetingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Sparkles } from '../assets/sparkle.svg';
import { ReactComponent as Check } from '../assets/check.svg';
import { ReactComponent as Cancel } from '../assets/cancel.svg';
import { ReactComponent as Play } from '../assets/play.svg';
import { ReactComponent as Identify } from '../assets/identify.svg';
import { updateSingle } from '../../redux/meetingSlice';
import axios from 'axios';

const backendURL = process.env.REACT_APP_BACKEND_URL;

const generateSpeakerColorMap = (speakers: Speaker[]): Record<string, string> => {
  const colorMap: Record<string, string> = {};
  speakers.forEach((speaker, index) => {
    const hue = 190 + (index * 20) % 60;
    const saturation = 50 + (index * 10) % 30;
    const lightness = 75 + (index * 10) % 20;
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    if (speaker.first_name.startsWith('SPEAKER_')) {
      // Case if the speaker is a diarization default (eg. SPEAKER_00)
      colorMap[speaker.first_name] = color;
    } else if (speaker._id) {
      colorMap[speaker._id] = color;
    }
  });
  return colorMap;
};

const selectReducer = (state, action) => {
  switch (action.type) {
    case 'addTrans':
      state.transcripts.add(action.payload)
      return {transcripts: state.transcripts}
    case 'removeTrans':
        state.transcripts.delete(action.payload)
        return {transcripts: state.transcripts}
    case 'reset':
      return {transcripts: new Set<string>()}
  }
}

const MeetingDetails = forwardRef(() => {
  const dispatch = useDispatch();
  const meeting: Meeting | undefined = useSelector(selectActiveMeeting);
  const [hoveredEmail, setHoveredEmail] = useState<string | null>(null);
  const [copiedEmail, setCopiedEmail] = useState<string | null>(null);
  const [error, setError] = useState('');
  const [vidStart, setVidStart] = useState(0);
  const [selecting, setSelecting] = useState(false);
  const [selected, selectDispatch] = useReducer(selectReducer, {transcripts: new Set<string>()})
  const [confirming, setConfirming] = useState(false);
  const [reassing, setReassing] = useState(false);

  const updateMeeting = (toUpdate: Meeting) => {
    dispatch(updateSingle(toUpdate));
    dispatch(setActive(toUpdate));
  }

  const confirmTranscripts = () => {
    // Check if none selected
    if (!(selected?.transcripts.size > 0)) return;

    let newMeeting = {...meeting} as Meeting;
    let speakers = new Set<string>()

    let validTransIds: string[] = []
    newMeeting.transcripts = newMeeting.transcripts.map(trans => {
      if (selected?.transcripts.has(trans.id) && trans.auto_identified) {
        const newTrans = {...trans}
        newTrans.auto_identified = false;
        if (newTrans.speaker_id && !newTrans.speaker_id.startsWith('SPEAKER_')) speakers.add(newTrans.speaker_id);
        validTransIds.push(trans.id ?? '')
        return newTrans
      }
      return trans;
    })

    if (validTransIds.length > 0) {
      updateMeeting(newMeeting);
      axios.post(backendURL + `/confirm/${meeting?._id}`, {
        speaker_ids: Array.from(speakers),
        transcript_ids: validTransIds,
      } as ConfirmationSchema);
    }
  }

  if ((error !== '') || !meeting) {
    return (
      <div className="flex items-center justify-center h-full p-4">
        <p className="text-gray-500 text-lg">{(error !== '') ? String(error) : "Select a meeting to view"}</p>
      </div>
    );
  }

  const findSpeakerName = (speakerId: string): string => {
    // Case if speaker is a diarization default
    if (speakerId.startsWith('SPEAKER_')) return speakerId;

    const speaker = meeting.speakers.find(speaker => speaker._id === speakerId);
    return speaker ? `${speaker.first_name} ${speaker.last_name}` : 'Speaker Not Found';
  };

  const parsedDate = parseISO(meeting.date);
  const formattedDate = `${format(parsedDate, 'EEE, MMM d, yyyy, h:mm a')}`;
  const completeDateInfo = `${formattedDate} (${formatDistanceToNow(parsedDate, { addSuffix: true })})`;


  const speakerColorMap = generateSpeakerColorMap(meeting.speakers);


  const copyEmailToClipboard = (email: string) => {
    navigator.clipboard.writeText(email).then(() => {
      setCopiedEmail(email);
      setTimeout(() => setCopiedEmail(null), 1000);
    }).catch(err => {
      console.error('Failed to copy email: ', err);
    });
  };


  const startStopSelecting = () => {
    if (!selecting) {
      document.onselectstart = () => false;
      selectDispatch({type: 'reset'})
    } else {
      setConfirming(false);
      setReassing(false);
      document.onselectstart = () => true;
    }
    setSelecting(!selecting);
  }

  
  const onClickTranscript= (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>, 
    transcript: Transcript
  ) => {
    if (!selecting) return;

    if (event.shiftKey) {
      if (selected?.transcripts.has(transcript.id)) {
        // Remove (shift click)
        meeting.transcripts.forEach(trans => {
          if (trans.speaker_id === transcript.speaker_id) {
            selectDispatch({type: 'removeTrans', payload: trans.id})
          }
        })
      } else {
        // Add (shift click)
        meeting.transcripts.forEach(trans => {
          if (trans.speaker_id === transcript.speaker_id) {
            selectDispatch({type: 'addTrans', payload: trans.id})
          }
        })
      }
    } else {
      if (selected?.transcripts.has(transcript.id)) {
        // Remove
        selectDispatch({type: 'removeTrans', payload: transcript.id})
      } else {
        // Add
        selectDispatch({type: 'addTrans', payload: transcript.id})
      }
    }
  }

  return (
    <div className="flex flex-col h-full">
      {/* Title Block */}
      <div className="sticky top-0 bg-white border rounded-lg border-gray-300 p-4 shadow z-10">
        <h2 className="text-lg font-bold">{meeting.name}</h2>
      </div>

      {/* Details Block */}
      <div className="flex-grow p-4 mt-2 overflow-y-auto">
        <div className="border rounded-lg p-4 mb-4 shadow hover:shadow-md transition-shadow">

          <p className="text-gray-700">Date: {completeDateInfo}</p>

          <h3 className="mt-4 mb-2 text-lg font-semibold">Speakers</h3>
          <ul>
            {meeting.speakers.map((speaker, index) => (
              <li key={index} className="flex items-center">
                {speaker.first_name} {speaker.last_name}
                {speaker.email && 
                  <span
                    className="relative text-gray-500 ml-2 cursor-pointer hover:text-blue-500"
                    onClick={() => copyEmailToClipboard(speaker.email)}
                    onMouseEnter={() => setHoveredEmail(speaker.email)}
                    onMouseLeave={() => setHoveredEmail(null)}
                  >
                    &lt;{speaker.email}&gt;
                    <span className={`tooltip ${hoveredEmail === speaker.email ? 'show' : ''}`}>
                      {copiedEmail === speaker.email ? 'Copied!' : 'Copy email'}
                    </span>
                  </span>
                }
              </li>
            ))}
          </ul>

          <h3 className="mt-4 mb-2 text-lg font-semibold">Recording</h3>
          {meeting._id && meeting.recording_type && <MediaPlayer meeting_id={meeting._id} recording_type={meeting.recording_type} seekTo={vidStart}/>}

          <div className='flex flex-row mt-4 mb-2 '>
            <h3 className="text-lg font-semibold">Summary</h3>
            <Sparkles className='mt-1 sparkle'/>
          </div>
          <p> {meeting.summary}</p>

          <h3 className="mt-4 mb-2 text-lg font-semibold">Transcript</h3>
          {meeting.transcripts.map((transcript, index) => (
            <div
              key={index}
              className="border rounded-lg p-2 mb-2 shadow hover:shadow-md transition-shadow"
              style={{ 
                backgroundColor: (selecting && selected?.transcripts.has(transcript.id ?? '')) ? '#a2ed9c' : (speakerColorMap[transcript.speaker_id ?? ''] || '#FFFFFF'),
                filter: (!selecting || selected?.transcripts.has(transcript.id ?? '')) ? 'none' : 'grayscale(70%)'
               }}
              onClick={(event) => onClickTranscript(event, transcript)}
            >
              <div className='top-transcript'>
                <div style={{display: 'flex'}}>
                  <p><strong>Speaker:</strong> {findSpeakerName(transcript.speaker_id ?? '')}</p>
                  {transcript.auto_identified && <Sparkles className='sparkle'/>}
                </div>
              </div>
              <div style={{display: 'flex'}}>
                <p><strong>Time:</strong> {transcript.time}</p>
                <button className='seeker' onClick={() => setVidStart(transcript.exact_time)}>
                  <Play />
                </button>
              </div>
              <p>{transcript.text}</p>
            </div>
          ))}

          {/* Reassignment and confirmation */}
          <div className='selector'>
            {!selecting && <>
              <button
                title="Confirm that the detection is accurate"
                onClick={() => {setConfirming(true); startStopSelecting()}} 
                className='toolbar-button'>
                <Check />
              </button>
              <button
                title="Reassing speakers"
                onClick={() => {setReassing(true); startStopSelecting()}} 
                className='toolbar-button'>
                <Identify />
              </button>
            </>}
            {selecting && <>
              <button
                title="Cancel"
                onClick={startStopSelecting} 
                className='toolbar-button red'>
                <Cancel />
              </button>
              {confirming &&
                <button
                  title="Submit seleciton"
                  onClick={() => {confirmTranscripts(); startStopSelecting();}} 
                  className='toolbar-button green'>
                  <Check />
                </button>
              }
              {reassing && 
                <Popup 
                  trigger={
                    <button 
                      title="Submit seleciton"
                      className='toolbar-button green'>
                      <Check />
                    </button>
                  }
                  position="top right">
                    <SpeakerAssigner transcriptIds={selected?.transcripts} onSubmit={startStopSelecting} />
                </Popup>
              }
            </>}
          </div>
        </div>
      </div>
    </div>
  );
});

export default MeetingDetails;

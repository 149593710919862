// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FiltersView_clear-button__AFrpf {
    width: 32px;
    height: 32px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ef5858;
    border-radius: 5px;
    transition: background-color 0.3s;
    color: #fff;
  }
  
  .FiltersView_clear-button__AFrpf:hover {
    background-color: #b63838;
  }
  
  .FiltersView_clear-button-highlight__10bOn {
    background-color: #b63838;
  }
  
  .FiltersView_clear-button__AFrpf svg {
    width: 20px;
    height: 20px;
    fill: #fff;
  }
  


.FiltersView_chip__mlFxY {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 4px 4px 7px;
    border-radius: 10px;
    overflow: clip;
    background-color: #ccc;
    transition: background-color 0.3s;

    .FiltersView_cancel__rkc8I {
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 4px;

        svg {
            height: 12px;
            width: 12px;
            margin-right: 2px;
            transition: margin-right 0.1s;
            transition: height 0.1s;
            transition: width 0.1s;
        }
    }
}

.FiltersView_chip__mlFxY:hover {
    background-color: #aaa;
}

`, "",{"version":3,"sources":["webpack://./src/components/FiltersView/FiltersView.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,kBAAkB;IAClB,iCAAiC;IACjC,WAAW;EACb;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,UAAU;EACZ;;;;AAIF;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,wBAAwB;IACxB,mBAAmB;IACnB,cAAc;IACd,sBAAsB;IACtB,iCAAiC;;IAEjC;QACI,aAAa;QACb,gBAAgB;QAChB,mBAAmB;QACnB,gBAAgB;;QAEhB;YACI,YAAY;YACZ,WAAW;YACX,iBAAiB;YACjB,6BAA6B;YAC7B,uBAAuB;YACvB,sBAAsB;QAC1B;IACJ;AACJ;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".clear-button {\n    width: 32px;\n    height: 32px;\n    padding: 5px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #ef5858;\n    border-radius: 5px;\n    transition: background-color 0.3s;\n    color: #fff;\n  }\n  \n  .clear-button:hover {\n    background-color: #b63838;\n  }\n  \n  .clear-button-highlight {\n    background-color: #b63838;\n  }\n  \n  .clear-button svg {\n    width: 20px;\n    height: 20px;\n    fill: #fff;\n  }\n  \n\n\n.chip {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 4px 4px 4px 7px;\n    border-radius: 10px;\n    overflow: clip;\n    background-color: #ccc;\n    transition: background-color 0.3s;\n\n    .cancel {\n        display: flex;\n        margin-top: auto;\n        margin-bottom: auto;\n        margin-left: 4px;\n\n        svg {\n            height: 12px;\n            width: 12px;\n            margin-right: 2px;\n            transition: margin-right 0.1s;\n            transition: height 0.1s;\n            transition: width 0.1s;\n        }\n    }\n}\n\n.chip:hover {\n    background-color: #aaa;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clear-button": `FiltersView_clear-button__AFrpf`,
	"clear-button-highlight": `FiltersView_clear-button-highlight__10bOn`,
	"chip": `FiltersView_chip__mlFxY`,
	"cancel": `FiltersView_cancel__rkc8I`
};
export default ___CSS_LOADER_EXPORT___;

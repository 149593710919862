// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-button {
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #5388f0;
  border-radius: 5px;
  transition: background-color 0.3s;
  color: #fff;
}

.toolbar-button:hover {
  background-color: #2054bb;
}

.toolbar-button-highlight {
  background-color: #2054bb;
}

.toolbar-button svg {
  width: 20px;
  height: 20px;
  fill: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Toolbar/Toolbar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,kBAAkB;EAClB,iCAAiC;EACjC,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".toolbar-button {\n  width: 40px;\n  height: 40px;\n  padding: 5px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: #5388f0;\n  border-radius: 5px;\n  transition: background-color 0.3s;\n  color: #fff;\n}\n\n.toolbar-button:hover {\n  background-color: #2054bb;\n}\n\n.toolbar-button-highlight {\n  background-color: #2054bb;\n}\n\n.toolbar-button svg {\n  width: 20px;\n  height: 20px;\n  fill: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

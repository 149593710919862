// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.login_loginForm__8nNhz {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border: 3px solid black;
    border-radius: 10px;
}

.login_loginInput__U6Vzj {
    padding: 3px;
    padding-left: 5px;
    border: 2px solid black;
    background-color: lightgrey;
    border-radius: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/login.module.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;IACvB,2BAA2B;IAC3B,mBAAmB;AACvB","sourcesContent":["\n\n.loginForm {\n    display: flex;\n    flex-direction: column;\n    width: 300px;\n    padding: 20px;\n    border: 3px solid black;\n    border-radius: 10px;\n}\n\n.loginInput {\n    padding: 3px;\n    padding-left: 5px;\n    border: 2px solid black;\n    background-color: lightgrey;\n    border-radius: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": `login_loginForm__8nNhz`,
	"loginInput": `login_loginInput__U6Vzj`
};
export default ___CSS_LOADER_EXPORT___;

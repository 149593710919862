// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.SpeakerAssigner_modal__vUD7f {
    width: 400px;
    height: 250px;
    padding: 20px 20px 12px 20px;
    box-shadow: 10px 10px 20px #777;
    border-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.SpeakerAssigner_search__EW55b {
    flex-grow: 4;
    border: solid black 2px;
    border-radius: 5px;
    padding: 3px;
}


.SpeakerAssigner_action-button__o3IX3 {
    color: white;
    background-color: #5388f0;
    padding: 6px;
    border-radius: 5px;
}

.SpeakerAssigner_speaker-list__6zZX- {
    background-color: #e9ecef;
    flex-grow: 5;
    margin-top: 10px;
    margin-bottom: 5px;
    overflow-y: scroll;
    padding: 5px;
}

.SpeakerAssigner_bottom__7Cgml {
    display: flex;
    flex-direction: row;
    padding-left: 5px
}


.SpeakerAssigner_empty-speakers__As-cb {
    text-align: center;
    padding-top: 50px;
    color: gray;
}

.SpeakerAssigner_speaker-creator__Zl-3B {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/components/SpeakerAssigner/SpeakerAssigner.module.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;IACZ,aAAa;IACb,4BAA4B;IAC5B,+BAA+B;IAC/B,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;AAChB;;;AAGA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB;AACJ;;;AAGA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":["\n\n.modal {\n    width: 400px;\n    height: 250px;\n    padding: 20px 20px 12px 20px;\n    box-shadow: 10px 10px 20px #777;\n    border-radius: 20px;\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n}\n\n.search {\n    flex-grow: 4;\n    border: solid black 2px;\n    border-radius: 5px;\n    padding: 3px;\n}\n\n\n.action-button {\n    color: white;\n    background-color: #5388f0;\n    padding: 6px;\n    border-radius: 5px;\n}\n\n.speaker-list {\n    background-color: #e9ecef;\n    flex-grow: 5;\n    margin-top: 10px;\n    margin-bottom: 5px;\n    overflow-y: scroll;\n    padding: 5px;\n}\n\n.bottom {\n    display: flex;\n    flex-direction: row;\n    padding-left: 5px\n}\n\n\n.empty-speakers {\n    text-align: center;\n    padding-top: 50px;\n    color: gray;\n}\n\n.speaker-creator {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    align-items: center;\n    margin-top: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `SpeakerAssigner_modal__vUD7f`,
	"search": `SpeakerAssigner_search__EW55b`,
	"action-button": `SpeakerAssigner_action-button__o3IX3`,
	"speaker-list": `SpeakerAssigner_speaker-list__6zZX-`,
	"bottom": `SpeakerAssigner_bottom__7Cgml`,
	"empty-speakers": `SpeakerAssigner_empty-speakers__As-cb`,
	"speaker-creator": `SpeakerAssigner_speaker-creator__Zl-3B`
};
export default ___CSS_LOADER_EXPORT___;

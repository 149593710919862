import React, {useState} from "react";
import styles from './SpeakerAssigner.module.css';
import { Speaker } from "../../client";
import axios from "axios";

const backendURL = process.env.REACT_APP_BACKEND_URL;

const SpeakerCreator = ({afterCreate}) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');


    const sendSpeaker = async () => {
        const res = await axios.post(backendURL + `/speakers`,{
            first_name: firstName,
            last_name: lastName,
            email: email,
            created_at: new Date().toISOString()
        } as Speaker)

        if (res.status !== 200) {
            console.log(res.statusText);
        }
    }

    const createSpeaker = () => {
        sendSpeaker().then(() => afterCreate());
    }


    return (
        <div className={styles['speaker-creator']}>
            <input
                type='text'
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                className={styles['search']}
                placeholder="First Name"
                />
            <input
                type='text'
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                className={styles['search']}
                placeholder="Last Name"
                />
            <input
                type='text'
                value={email}
                onChange={e => setEmail(e.target.value)}
                className={styles['search']}
                placeholder="Email"
                />
            <button 
                onClick={() => createSpeaker()}
                className={styles['action-button']}>
                Create New Speaker
            </button>
        </div>
        
    );
}

export default SpeakerCreator;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Uploader_cancel-button__59OUQ {
    padding: 4px 6px 4px 6px;
    border-radius: 5px;
    background-color: lightgray;
}

.Uploader_loginForm__pFJkP {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.Uploader_loginInput__D0gXC {
    padding: 3px;
    padding-left: 5px;
    border: 2px solid black;
    background-color: lightgrey;
    border-radius: 10px;
}


.Uploader_loading__5tfDA {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 3rem;
    font-weight: bold;
    width: 400px;
}

`, "",{"version":3,"sources":["webpack://./src/components/Uploader/Uploader.module.css"],"names":[],"mappings":";AACA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,uBAAuB;IACvB,2BAA2B;IAC3B,mBAAmB;AACvB;;;AAGA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,iBAAiB;IACjB,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":["\n.cancel-button {\n    padding: 4px 6px 4px 6px;\n    border-radius: 5px;\n    background-color: lightgray;\n}\n\n.loginForm {\n    display: flex;\n    flex-direction: column;\n    width: 300px;\n}\n\n.loginInput {\n    padding: 3px;\n    padding-left: 5px;\n    border: 2px solid black;\n    background-color: lightgrey;\n    border-radius: 10px;\n}\n\n\n.loading {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding-top: 3rem;\n    font-weight: bold;\n    width: 400px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cancel-button": `Uploader_cancel-button__59OUQ`,
	"loginForm": `Uploader_loginForm__pFJkP`,
	"loginInput": `Uploader_loginInput__D0gXC`,
	"loading": `Uploader_loading__5tfDA`
};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';
import { Meeting } from '../../client';
import { ReactComponent as StarUnfilledIcon } from '../assets/star-unfilled-icon.svg';
import { ReactComponent as StarFilledIcon } from '../assets/star-filled-icon.svg';
import { ReactComponent as UnreadIcon } from '../assets/unread-icon.svg';
import { ReactComponent as ReadIcon } from '../assets/read-icon.svg';
import './MeetingCard.css';

type Props = {
  meeting: Meeting;
  isSelected: boolean;
  onClick: () => void;
  onToggleFavourite: () => void;
  onToggleReadStatus: () => void;
};

const MeetingCard = ({
  meeting,
  isSelected,
  onClick,
  onToggleFavourite,
  onToggleReadStatus
}: Props) => {
  const formattedDate = new Date(meeting.date).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

  return (
    <div
    className={`border rounded-lg p-4 mb-4 shadow hover:shadow-md transition-shadow cursor-pointer
      ${isSelected ? 'bg-blue-100 border-blue-500' : meeting.is_read ? '' : 'meeting-card-unread'}`}
    onClick={onClick}
    >
      <div className="flex items-center justify-between">
        <div className="flex-grow">
          <h2 className="text-lg font-semibold">{meeting.name}</h2>
          <p className='meeting-card-summary'>{meeting.summary}</p>
          <p className="text-gray-700">{formattedDate}</p>
        </div>
        <div className="flex items-center mt-auto">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onToggleFavourite();
            }}
            className="meeting-card-button meeting-card-favourite-button"
            title={meeting.is_favourite ? 'Remove from favourites' : 'Add to favourites'}
          >
            {meeting.is_favourite ? <StarFilledIcon /> : <StarUnfilledIcon />}
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onToggleReadStatus();
            }}
            className="meeting-card-button meeting-card-read-button"
            title={meeting.is_read ? 'Mark as unread' : 'Mark as read'}
          >
            {meeting.is_read ? <ReadIcon /> : <UnreadIcon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MeetingCard;
